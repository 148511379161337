import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import withAuthenticationRequired from "./withAuthenticationRequired";

import Login from "./components/Login";
import Home from "./components/Home";
import ChatPDF from "./components/ChatPDF";
import ChatWebsite from "./components/ChatWebsite";
import TextAI from "./components/TextAI";
import VoiceAI from "./components/VoiceAI";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/home" component={withAuthenticationRequired(Home)} />
        <Route path="/chatpdf" component={withAuthenticationRequired(ChatPDF)} />
        <Route path="/chatwebsite" component={withAuthenticationRequired(ChatWebsite)} />
        <Route path="/textai" component={withAuthenticationRequired(TextAI)} />
        <Route path="/voiceai" component={withAuthenticationRequired(VoiceAI)} />
      </Switch>
    </Router>
  );
};

export default App;
