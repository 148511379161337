import React, { useState } from "react";
import { ReactMic } from "react-mic";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  TextField,
  IconButton,
  Box,
  Typography,
  MenuItem,
  Select
} from "@mui/material";
import styled from "@emotion/styled";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "./VoiceAI.css";
import logo from "./logo.png";
import {
  DownOutlined,
  UserOutlined,
  AliwangwangFilled,
  GitlabFilled,
  CrownTwoTone,
  FileFilled,
  SmileTwoTone,
  CheckCircleTwoTone,
  FireTwoTone,
  FilePdfTwoTone,
  DownCircleTwoTone,
  SendOutlined,
  FileAddOutlined,
  SettingOutlined,
  SmileOutlined
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Space,
  Tooltip,
  message,
  Divider,
  Input,
  Cascader
} from "antd";

const { Search } = Input;
const handleButtonClick = (e) => {
  message.info("Click on left button.");
  console.log("click left button", e);
};
const handleMenuClick = (e) => {
  message.info("Click on menu item.");
  console.log("click", e);
};
const items = [
  {
    label: "AI Assitant",
    key: "1",
    icon: <GitlabFilled />
  },
  {
    label: "ChatPDF",
    key: "2",
    icon: <FilePdfTwoTone />,
    danger: true
  },
  {
    label: "Chat with Website",
    key: "3",
    icon: <AliwangwangFilled />
  },
  {
    label: "GPT-4",
    key: "4",
    icon: <FireTwoTone />,
    danger: true,
    disabled: true
  }
];
const menuProps = {
  items,
  onClick: handleMenuClick
};

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  background-color: #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

const DropdownWrapper = styled("div")`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const Logo = styled("img")`
  width: 35.0347px;
  height: 32.8004px;
  // mix-blend-mode: luminosity; // Add this line
  @media (max-width: 600px) {
    width: 40px;
    height: 30px;
  }
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  align-items: center;
  padding-top: 5%;

  @media (max-width: 600px) {
    padding-top: 15%;
  }
`;

const ChatWindow = styled(Box)`
  width: 75%;
  height: 65%;
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  overflow-y: auto;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 75%;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const ChatMessage = styled(Typography)`
  margin-bottom: 10px;
  word-wrap: break-word;
  border-bottom: 1px solid #444;
  padding-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export default function VoiceAI() {

  async function sendMessageToAPI(message) {
    try {
      // const apiKey = process.env.FASTAPI_API_KEY;

      const response = await fetch(
        "https://fastapi-for-ai-bz2pzdixza-ue.a.run.app/process_message/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            api_key: process.env.REACT_APP_AI_ASSISTANT_FASTAPI_KEY
          },
          body: JSON.stringify({ message: message.text })
        }
      );

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error sending message to API:", error);
      return null;
    }
  }

  const [model, setModel] = useState("Model-1");
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = async () => {
    if (inputText.trim() !== "") {
      const userMessage = { sender: "user", text: inputText };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputText("");

      const apiResponse = await sendMessageToAPI(userMessage);
      if (apiResponse) {
        const botMessage = {
          sender: "chatbot",
          text: apiResponse.output_message
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      }
    }
  };



  return (
    <Container>
      <Header>
        <Logo src={logo} alt="Logo" />
        <DropdownWrapper>
          <Space wrap>
            <Dropdown.Button
              menu={menuProps}
              placement="bottom"
              icon={<DownCircleTwoTone />}
            >
              Choose a Model
            </Dropdown.Button>
          </Space>
        </DropdownWrapper>
      </Header>

      <Divider />
      <ChatWindow>
        {messages.map((message, index) => (
          <ChatMessage
            key={index}
            color={message.sender === "user" ? "primary" : "secondary"}
            sx={{
              fontFamily: "Arial, sans-serif",
              color: message.sender === "user" ? "#1E90FF" : "#FFC0CB",
              backgroundColor: message.sender === "user" ? "#222" : "#2C3E50",
              borderRadius: "5px",
              padding: "5px"
            }}
          >
            {message.sender === "user" ? "You: " : `${model}: `}
            {message.fileType === "file" ? (
              <a
                href={URL.createObjectURL(message.file)}
                download={message.file.name}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {message.text}
              </a>
            ) : (
              message.text
            )}
          </ChatMessage>
        ))}
      </ChatWindow>
      <InputContainer>
        <Input
          placeholder="Ask your AI Assistant Anything"
          prefix={<SmileOutlined />}
          enterButton
          value={inputText}
          onChange={handleInputChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />
        <></>

        <IconButton color="primary" onClick={handleSendMessage}>
          <Space direction="vertical">
            <Space wrap>
              <Tooltip title="Send Message">
                <Button type="primary" shape="circle" icon={<SendOutlined />} />
              </Tooltip>
            </Space>
          </Space>
        </IconButton>
      </InputContainer>
    </Container>
  );
}
