// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCajy089DTb3RWpSDwDN0s81bxP53ZTz-w",
  authDomain: "corded-protocol-341122.firebaseapp.com",
  projectId: "corded-protocol-341122",
  storageBucket: "corded-protocol-341122.appspot.com",
  messagingSenderId: "123580742136",
  appId: "1:123580742136:web:d6a9020faefa1b1a74fe0d",
  measurementId: "G-LCFV2WTTF5"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export default auth;
