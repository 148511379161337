import React from "react";
import { Redirect } from "react-router-dom";
import { getAuth } from "firebase/auth";

const withAuthenticationRequired = (Component) => {
  return (props) => {
    const auth = getAuth();
    const isAuthenticated = !!auth.currentUser;

    if (!isAuthenticated) {
      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  };
};

export default withAuthenticationRequired;
